import axios from "axios";

export const countrySites = {
    US: "paylatertravel.com",
    AU: "paylatertravel.com.au"
};

export const getGeoInfo = async () => {
    return axios
        .get("https://ipapi.co/json/")
        .then((response) => {
            const { data } = response;
            const { country_code: countryCode, country_name: countryName } = data;
            const hostname = window.location.hostname
                .replace("pltstage.", "")
                .replace("www.", "")
                .replace("local.", "");

            if (!countrySites[countryCode] || hostname === countrySites[countryCode] || hostname === "localhost")
                return;
            return Promise.resolve({
                countryCode,
                countryName,
                countryModal: true
            });
        })
        .catch((error) => {
            console.log(error);
        });
};
